<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="ค้นหารายการขาย"
        :filter="filter"
        @search="handleSearch"
        @clear="clearFilter"
      >
        <template v-slot:filter-title>
          <b-form-checkbox
            v-model="filter.currentOrAll"
            :value="1"
            :unchecked-value="0"
            @change="handleChecked"
          >
            แสดงเฉพาะสาขา {{ branchName }}
          </b-form-checkbox>
        </template>
        <template v-slot:filter-option>
          <b-col>
            <InputText
              v-model="filter.memberId"
              textFloat="หมายเลขสมาชิก (Member No.)"
              placeholder="หมายเลขสมาชิก (Member No.)"
              type="text"
              name="memberId"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.memberName"
              textFloat="ชื่อสมาชิก (Member Name)"
              placeholder="ชื่อสมาชิก (Member Name)"
              type="text"
              name="memberName"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.telephone"
              textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)"
              type="text"
              name="telephone"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              textFloat="หมายเลขใบเสร็จ (Mim/Pos Ref No.)"
              placeholder="หมายเลขใบเสร็จ (Mim/Pos Ref No.)"
              v-model="filter.invoiceNo"
              type="text"
              name="invoiceNo"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันเริ่มต้น (From Date)"
              name="fromDate"
              ref="fromDate"
              v-model="filter.fromDate"
              @input="changeFromDate"
              :v="$v.filter.fromDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันที่สิ้นสุด (To Date)"
              name="toDate"
              ref="toDate"
              v-model="filter.toDate"
              @input="onChangeToDate"
              :v="$v.filter.toDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputText
              textFloat="รหัสพนักงานขาย (Salesperson No.)"
              placeholder="รหัสพนักงานขาย (Salesperson No.)"
              v-model="filter.salesPersonId"
              type="text"
              name="salesPersonId"
              className="mb-3"
              @onEnter="handleSearch"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="สถานะการซื้อ (Order Status)"
              isRequired
              :options="statusList"
              v-model="filter.transactionStatus"
              @onDataChange="(val) => (filter.transactionStatus = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(invoice_no)="data">
                <router-link
                  :to="`/transaction/detail/${data.item.id}/${data.item.user_guid}`"
                >
                  <u> {{ data.item.invoice_no }}</u>
                </router-link>
              </template>
              <template v-slot:cell(customer_name)="data">
                <router-link
                  class="flex-cols"
                  :to="`/transaction/detail/0/${data.item.user_guid}`"
                >
                  <u>{{ data.item.member_id }}</u>
                  <u> {{ data.item.customer_name }}</u>
                  <u>{{ data.item.telephone }}</u>
                </router-link>
              </template>
              <template v-slot:cell(point)="data">
                {{ data.item.point | numeral("0,0") }}
                <p v-if="data.item.extra_point_name">
                  {{ truncateText(data.item.extra_point_name, 10) }} : {{  data.item.extra_point | numeral("0,0")}}
                </p>
              </template>
              <template v-slot:cell(branch_name)="data">
                <div>{{ data.item.sales_no }}</div>
                <div>{{ data.item.branch_name }}</div>
              </template>
              <template v-slot:cell(cancel_transaction_ref)="data">
                <router-link
                  v-if="
                    data.item.cancel_transaction_id != null &&
                    data.item.cancel_transaction_id != data.item.id
                  "
                  :to="`/transaction/detail/${data.item.id}/${data.item.user_guid}`"
                >
                  <u> {{ data.item.cancel_transaction_ref }}</u>
                </router-link>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(grand_total)="data">
                <div>{{ data.item.grand_total | numeral("0,0.00") }}</div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div>
                  {{
                    $moment(data.item.created_time)
                      .add(543, "years")
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div>
                  {{ data.item.status }}
                  <span class="text-danger" v-if="data.item.is_cancel == 1">
                    (Void/Returned)
                  </span>
                </div>
              </template>
              <template v-slot:cell(consolidation)="data">
                <u
                  v-if="
                    !data.item.userTransactionMergePointRefId &&
                    !data.item.cancel_transaction_id &&
                    data.item.status != 'Void'
                  "
                  class="text-primary pointer"
                  @click="$refs.modalConsolidation.show(data.item)"
                >
                  รวมบิล
                </u>
                <div v-else class="text-disabled">รวมบิล</div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalConsolidation
      ref="modalConsolidation"
      @reloadDataTable="getList"
      :branchId="branchId"
    />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import ModalConsolidation from "@/components/transaction/ModalConsolidation";
import { mapGetters } from "vuex";
import { minValue, maxValue } from "vuelidate/lib/validators";

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) >= new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) >= new Date(value);
};

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect,
    InputText,
    InputDatePickerFilter,
    ModalConsolidation,
  },
  name: "ReportTransaction",
  data() {
    return {
      fields: [
        {
          key: "invoice_no",
          label: "หมายเลขใบเสร็จ",
        },
        {
          key: "cancel_transaction_ref",
          label: "หมายเลขอ้างอิง ",
          tdClass: "min-w-200",
        },

        {
          key: "customer_name",
          label: "ลูกค้า",
        },
        {
          key: "grand_total",
          label: "ยอดสุทธิ (Net Amount)",
        },
        {
          key: "point",
          label: "คะแนน",
          tdClass: "text-left",
        },
        {
          key: "branch_name",
          label: "พนักงานขาย/สาขา (Branch)",
        },

        {
          key: "created_time",
          label: "วันที่/เวลา (Date/Time)",
        },
        {
          key: "status",
          label: "สถานะ (Status)",
        },
        {
          key: "consolidation",
          label: "การรวมบิล (Consolidation)",
        },
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        branchId: 0,
        page: 1,
        take: 10,
        fromDate: null,
        toDate: null,
        billId: "",
        memberName: "",
        telephone: "",
        salesPersonId: "",
        transactionStatus: 0,
        invoiceNo: "",
        currentOrAll: 1,
        memberId: "",
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
      isLoading: true,
      statusList: [],
      today: null,
    };
  },
  validations: {
    filter: {
      fromDate: {
        maxValue(val, { toDate }) {
          return maxDate(val, toDate);
        },
      },
      toDate: {
        minValue(val, { fromDate }) {
          return minDate(val, fromDate);
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
      branchName: "getBranchName",
    }),
  },
  created: async function () {
    this.filter.branchId = this.branchId;
    this.filter.memberId = this.$route.query.memberId || "";
    this.filter.fromDate = this.$route.query.startDate || null;
    this.filter.toDate = this.$route.query.endDate || null;

    this.today = this.$moment().format("YYYY-MM-DDT00:00:00");
    this.getStatusList();
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) return;

      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.from_date = payload.fromDate;
      payload.to_date = payload.toDate
        ? this.$moment(payload.toDate).add(1, "days").format("YYYY-MM-DD")
        : null;
      payload.member_id = payload.memberId;
      payload.name = payload.memberName;
      payload.branch_id = payload.branchId;
      payload.transaction_status = payload.transactionStatus;
      payload.invoice_no = payload.invoiceNo;
      payload.current_or_all = payload.currentOrAll;
      payload.sales_person_id = payload.salesPersonId;

      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/transaction/get_transactions`, payload)
        .then((data) => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    getStatusList: async function () {
      let list = [{ id: 0, name: "ทั้งหมด" }];

      await this.axios
        .get(`${this.$baseUrl}/transaction/get_status_list`)
        .then((data) => {
          if (data.result == 1) {
            this.statusList = list.concat(data.detail);
          }
        });
    },
    handleSearch(value) {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    clearFilter() {
      this.$v.filter.$reset();

      this.filter = {
        branchId: 0,
        page: 1,
        take: 10,
        fromDate: null,
        toDate: null,
        billId: "",
        memberName: "",
        telephone: "",
        salesPersonId: "",
        transactionStatus: 0,
        invoiceNo: "",
        currentOrAll: 1,
      };
      this.getList();
    },
    changeFromDate(val) {
      this.filter.fromDate = val;
    },
    onChangeToDate(val) {
      this.filter.toDate = val;
    },
    handleChecked(val) {
      if (val) this.filter.branchId = this.branchId;
      else this.filter.branchId = 0;

      this.getList();
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table-main {
  table {
    min-width: max-content;
  }
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icon {
  font-size: 24px;
  color: #000 !important;
}
.flex-cols {
  display: flex;
  flex-direction: column;
}

::v-deep .header-title {
  gap: 8px;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  flex: 1;
}
</style>
